import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { flexRender, type HeaderGroup } from "@tanstack/react-table";
import TableResizer from "./TableResizer";

type TableHeadResizableProps<D> = Readonly<{
    headerGroups: HeaderGroup<D>[];
    getColumnSizeCssVariable: (columnId: string) => string;
}>;

function TableHeadResizable<D>({ headerGroups, getColumnSizeCssVariable }: TableHeadResizableProps<D>) {
    return (
        <TableHead>
            {headerGroups.map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                        <TableCell
                            key={header.id}
                            style={{
                                width: `calc(var(${getColumnSizeCssVariable(header.id)}) * 1px) `,
                            }}
                        >
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            {header.column.getCanResize() && (
                                <TableResizer
                                    onMouseDown={header.getResizeHandler()}
                                    onTouchStart={header.getResizeHandler()}
                                    isActive={header.column.getIsResizing()}
                                />
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </TableHead>
    );
}
export default TableHeadResizable;
