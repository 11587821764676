import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { ColumnDef, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";

import { ReactNode } from "react";
import MemoizedTableBody from "./MemoizedTableBody";
import Pagination from "./Pagination";
import TableBody from "./TableBody";
import TableHeadResizable from "./TableHeadResizable";
import TableRoot from "./TableRoot";
import { useColumnSizesCssVars } from "./useColumnSizesVars";
import { useFullWidthTable } from "./useFullWidthTable";

const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 25];

export interface UncontrolledTableProps<D> {
    data: D[];
    columns: ColumnDef<D, any>[];
    noDataText: string;
    isFetching?: boolean;
    fullHeight?: boolean;
    itemName?: string;
    pageSizeOptions?: number[];
    defaultPageSize?: number;
    className?: string;
    disableSorting?: boolean;
}

function UncontrolledTable<D>({
    data,
    columns,
    noDataText,
    isFetching,
    fullHeight,
    itemName,
    pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
    defaultPageSize,
    className,
    disableSorting,
}: Readonly<UncontrolledTableProps<D>>): ReactNode {
    const { sentinelRef, columnSizing, onColumnSizingChange: updateColumnSizes, tableRef } = useFullWidthTable<D>();

    const table = useReactTable({
        data,
        columns,
        enableColumnResizing: true,
        enableSorting: disableSorting !== undefined ? !disableSorting : true,
        columnResizeMode: "onChange",
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onColumnSizingChange: updateColumnSizes,
        state: {
            columnSizing,
        },
        initialState: {
            pagination: {
                pageSize: defaultPageSize ?? pageSizeOptions[0],
            },
        },
    });

    tableRef.current = table;

    const flatHeaders = table.getFlatHeaders();
    const totalSize = table.getTotalSize();

    const { pageSize, pageIndex } = table.getState().pagination;

    // Column sizing is implemented with css variables, which is a performant way to resize columns
    // See: https://tanstack.com/table/v8/docs/framework/react/examples/column-resizing-performant
    const { cssVariables, getColumnCssConstant, getHeaderCssConstant } = useColumnSizesCssVars<D>(flatHeaders, columnSizing);

    return (
        <TableRoot className={className} fullHeight={fullHeight}>
            <TableContainer
                sx={{
                    borderTopLeftRadius: (theme) => theme.shape.borderRadiusLarge,
                    borderTopRightRadius: (theme) => theme.shape.borderRadiusLarge,
                }}
            >
                <div ref={sentinelRef} />
                <Table stickyHeader style={{ ...cssVariables, width: totalSize }}>
                    <TableHeadResizable headerGroups={table.getHeaderGroups()} getColumnSizeCssVariable={getHeaderCssConstant} />
                    {/* When resizing any column we will render this special memoized version of our table body */}
                    {table.getState().columnSizingInfo.isResizingColumn ? (
                        <MemoizedTableBody
                            table={table}
                            noDataText={noDataText}
                            isFetching={isFetching}
                            getColumnSizeCssVariable={getColumnCssConstant}
                        />
                    ) : (
                        <TableBody
                            table={table}
                            noDataText={noDataText}
                            isFetching={isFetching}
                            getColumnSizeCssVariable={getColumnCssConstant}
                        />
                    )}
                </Table>
            </TableContainer>
            <Pagination
                data={data}
                pageSizeOptions={pageSizeOptions}
                count={table.getFilteredRowModel().rows.length}
                pageSize={pageSize}
                page={pageIndex}
                itemName={itemName}
                onPageChange={(newPage) => {
                    table.setPageIndex(newPage);
                }}
                onPageSizeChange={(newSize) => {
                    table.setPageSize(newSize);
                }}
            />
        </TableRoot>
    );
}

export default UncontrolledTable;
