import { paginationClasses, styled, tableClasses, tableContainerClasses } from "@mui/material";
import classNames from "classnames";
import type { HTMLProps } from "react";

const tableRootClasses = {
    fullHeight: "TableRoot-fullHeight",
};

const Root = styled("div")({
    display: "flex",
    flexDirection: "column",

    [`& .${tableClasses.root}`]: {
        tableLayout: "fixed",
    },
    [`&.${tableRootClasses.fullHeight}`]: {
        height: "100%",
    },

    [`& .${tableContainerClasses.root}`]: {
        flexGrow: 1,
        flexShrink: 1,
    },

    [`& .${paginationClasses.root}`]: {
        flexShrink: 0,
    },
});

const TableRoot = ({ className, fullHeight, ...props }: Omit<HTMLProps<HTMLDivElement>, "as"> & { fullHeight?: boolean }) => {
    return <Root className={classNames(className, fullHeight && tableRootClasses.fullHeight)} {...props} />;
};

export default TableRoot;
