import MuiTableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { flexRender, type Table as TTable } from "@tanstack/react-table";

import { Typography } from "@mui/material";
import TableRowSkeletons from "./TableRowSkeletons";

interface BodyProps<D> {
    table: TTable<D>;
    noDataText: string;
    isFetching: boolean | undefined;
    getColumnSizeCssVariable: (columnId: string) => string;
}

function TableBody<D>({ table, isFetching, noDataText, getColumnSizeCssVariable }: Readonly<BodyProps<D>>) {
    return (
        <MuiTableBody>
            {!isFetching && table.getRowCount() === 0 ? (
                // Wrap in tr + td to generate valid nested DOM (tbody > tr > td)
                <TableRow>
                    <TableCell colSpan={table.getAllColumns().length} id="no-data-table-cell">
                        <Typography color="textSecondary">{noDataText}</Typography>
                    </TableCell>
                </TableRow>
            ) : null}
            {isFetching ? <TableRowSkeletons colSpan={table.getFlatHeaders().length} /> : null}
            {table.getRowModel().rows.map((row) => {
                return (
                    <TableRow key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                            return (
                                <TableCell key={cell.id} style={{ width: `calc(var(${getColumnSizeCssVariable(cell.column.id)}) * 1px)` }}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </MuiTableBody>
    );
}

export default TableBody;
