import { Grid, GridProps, LabelDisplayedRowsArgs, styled, TablePagination, tablePaginationClasses } from "@mui/material";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../translations/main-translations";
import PaginationActions from "../PaginationActions";
import PaginationActionsDense from "../PaginationActionsDense";

const StyledTablePagination = styled(TablePagination)<{
    component: React.ElementType<GridProps>;
}>(({ theme }) => ({
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    left: 0,
    overflow: "hidden",
    flexShrink: 0,

    borderBottomLeftRadius: theme.shape.borderRadiusLarge,
    borderBottomRightRadius: theme.shape.borderRadiusLarge,

    [`& .${tablePaginationClasses.toolbar}`]: {
        minHeight: theme.spacing(6),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    [`& .${tablePaginationClasses.selectLabel}`]: {
        color: theme.palette.text.secondary,
    },
    [`& .${tablePaginationClasses.displayedRows}`]: {
        color: theme.palette.text.secondary,
    },
    [`& .${tablePaginationClasses.selectRoot}`]: {
        marginLeft: (props: PaginationProps<unknown>) => theme.spacing(props.dense ? 0 : 1),
        marginRight: (props: PaginationProps<unknown>) => theme.spacing(props.dense ? 3 : 4),
    },
}));

interface PaginationProps<D> {
    count: number;
    pageSizeOptions: number[];
    page: number;
    pageSize: number;
    data: readonly D[];
    onPageChange: (newPage: number) => void;
    onPageSizeChange: (newSize: number) => void;
    itemName?: string;
    dense?: boolean;
}

function Pagination<D>(props: Readonly<PaginationProps<D>>) {
    const { count, pageSizeOptions, page, pageSize, data, onPageChange, onPageSizeChange, itemName, dense } = props;

    const { t: translate } = useTranslation();
    const renderDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => {
        const paginationKey = itemName ?? "none";
        return translate(`${translationKeys.VDLANG_PAGINATION}.${paginationKey}`, { from, to, count });
    };

    return (
        <StyledTablePagination
            labelRowsPerPage={translate(translationKeys.VDLANG_PAGE_SIZE)}
            labelDisplayedRows={renderDisplayedRows}
            rowsPerPageOptions={pageSizeOptions}
            count={count ?? data.length}
            rowsPerPage={pageSize}
            page={page}
            component={Grid}
            onPageChange={(e, newPage) => onPageChange(newPage)}
            onRowsPerPageChange={(e) => onPageSizeChange(Number(e.target.value))}
            ActionsComponent={dense ? PaginationActionsDense : PaginationActions}
        />
    );
}

export default Pagination;
