import type { Header } from "@tanstack/react-table";
import { useMemo } from "react";

function getHeaderCssConstant(columnId: string) {
    return `--header-${columnId}-size`;
}

function getColumnCssConstant(columnId: string) {
    return `--col-${columnId}-size`;
}

type UseColumnSizesCssVarsResult = {
    cssVariables: Record<string, number>;
    getHeaderCssConstant: (columnId: string) => string;
    getColumnCssConstant: (columnId: string) => string;
};

/**
 * Instead of calling `column.getSize()` on every render for every header and especially every data cell (very expensive),
 * we will calculate all column sizes at once at the root table level in a useMemo  and pass the column sizes down as CSS variables
 * to the <table> element.
 *
 * Example:
 * ```ts
 * const table = useReactTable({ ... });
 *
 * const columnSizeVars = useColumnSizesVars(table.getFlatHeaders(), table.getState().columnSizing);
 * ```
 *
 * and then rendering Header cells with
 * ```tsx
 * <TableCell style={{ width: `calc(var(${getHeaderCssConstant(header.id)}) * 1px) `}}>...</TableCell>
 * ```
 * and body cells with
 * ```tsx
 * <TableCell key={cell.id} style={{ width: `calc(var(${getColumnCssConstant(cell.column.id)}) * 1px)` }}>
 * ```
 */
export function useColumnSizesCssVars<D>(
    flatHeaders: Header<D, unknown>[],
    columnSizing: Record<string, number>,
): UseColumnSizesCssVarsResult {
    return useMemo(() => {
        // This hook needs to re-compute its result whenever columnSizing changes, although its not used within the function body
        // The hook linting rule will complain about an unused dependency. Instead of disabling the rule completely, create a void statement
        // here, so any other dependencies are still checked
        void columnSizing;

        const cssVariables: { [key: string]: number } = {};

        for (const header of flatHeaders) {
            // header.getSize() respects constraints such as minSize/maxSize, columnSizes does not
            const size = header.getSize();

            cssVariables[getHeaderCssConstant(header.id)] = size;
            cssVariables[getColumnCssConstant(header.column.id)] = size;
        }

        return { cssVariables, getHeaderCssConstant, getColumnCssConstant };
    }, [columnSizing, flatHeaders]);
}
