import { ColumnSizingState, type Header, type Table as TTable, type Updater } from "@tanstack/react-table";

import { clamp, partition, sumBy } from "lodash";
import React, { useCallback, useState } from "react";

function distributeEmptyWidth<D>(
    sentinelRef: React.RefObject<HTMLDivElement>,
    totalSize: number,
    flatHeaders: Header<D, unknown>[],
): Record<string, number> | undefined {
    if (sentinelRef.current == null) {
        return;
    }

    const containerWidth = sentinelRef.current.getBoundingClientRect().width;

    const remainingWidth = containerWidth > totalSize ? containerWidth - totalSize : 0;

    const [resizableColumns, fixedColumns] = partition(flatHeaders, (header) => header.column.getCanResize());

    const newColumnSizes: { [key: string]: number } = {};
    fixedColumns.forEach((header) => {
        newColumnSizes[header.id] = header.getSize();
    });

    const remainingPerResizableColumn = remainingWidth / resizableColumns.length;

    const [unconstrainedColumns, constrainedColumns] = partition(resizableColumns, (header) => {
        if (header.column.columnDef.maxSize == null) {
            return true;
        }
        const size = header.getSize();

        return size + remainingPerResizableColumn <= header.column.columnDef.maxSize;
    });

    let used = 0;
    constrainedColumns.forEach((header) => {
        if (header.column.columnDef.maxSize == null) {
            // Should not happen
            // eslint-disable-next-line no-console
            console.warn("Encountered constrained column without maxSize", header);
            return;
        }
        newColumnSizes[header.id] = header.column.columnDef.maxSize;
        used += header.column.columnDef.maxSize - header.getSize();
    });

    const remainingRemaining = Math.max(0, (remainingWidth - used) / unconstrainedColumns.length);
    unconstrainedColumns.forEach((header) => {
        newColumnSizes[header.id] = header.getSize() + remainingRemaining;
    });
    return newColumnSizes;
}

type UseFullWidthTableResult<D> = {
    /**
     * This needs to be set by the caller
     *
     * @type {(React.MutableRefObject<TTable<D> | null>)}
     */
    tableRef: React.MutableRefObject<TTable<D> | null>;

    /**
     * columnsSizing state for useTable hook
     *
     * @type {ColumnSizingState}
     */
    columnSizing: ColumnSizingState;

    /**
     * Callback to update the columnSizing state for useTable hook
     *
     */
    onColumnSizingChange: (sizing: Updater<ColumnSizingState>) => void;

    /**
     * Attach to an element that has the width of the table's container. Do not use the element containing the table itself, because
     * computing the width might be expensive there.
     *
     * @type {React.RefObject<HTMLDivElement>}
     */
    sentinelRef: React.RefObject<HTMLDivElement>;
};

/**
 * Make the columns stretch automatically to fill the available space in case the table columns do not fill the available space indicated by
 * the sentinel element width. The sentinel element can be the TableContainer or any other element of the same width. Watching the table container
 * might be slow with large tables, so it is recommended to use a sentinel element next to the actual Table.
 *
 * This hook both provides input data for the tanstack's `useTable` hook and at the same time requires its output for some computations.
 * The output is only used asynchronously, in a useLayoutEffect and an event handler, so a tableRef is used that has to be filled by the
 * caller
 *
 */
export function useFullWidthTable<D>(): UseFullWidthTableResult<D> {
    const tableRef = React.useRef<TTable<D> | null>(null);

    const sentinelRef = React.useRef<HTMLDivElement>(null);

    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({});

    const onColumnSizingChange = useCallback((sizing: Updater<ColumnSizingState>) => {
        if (tableRef.current == null) {
            return;
        }
        const table = tableRef.current;
        const flatHeaders = table.getFlatHeaders();

        const { isResizingColumn } = table.getState().columnSizingInfo;

        const containerWidth = sentinelRef.current?.getBoundingClientRect().width;

        setColumnSizing((oldSizing) => {
            const newSizing = typeof sizing === "function" ? sizing(oldSizing) : sizing;

            const newTotalWidth = sumBy(flatHeaders, (header) => {
                const min = header.column.columnDef.minSize ?? 0;
                const max = header.column.columnDef.maxSize ?? Number.MAX_SAFE_INTEGER;
                return clamp(newSizing[header.id], min, max);
            });

            if (containerWidth == null || containerWidth <= newTotalWidth) {
                // No correction needed
                return newSizing;
            }

            const correctionColumn = isResizingColumn !== false ? isResizingColumn : Object.keys(newSizing)[0];

            if (correctionColumn != null) {
                newSizing[correctionColumn] += containerWidth - newTotalWidth;
            }
            return newSizing;
        });
    }, []);

    React.useLayoutEffect(() => {
        if (sentinelRef.current == null || tableRef.current == null) {
            return;
        }

        const table = tableRef.current;
        const totalSize = table.getTotalSize();
        const flatHeaders = table.getFlatHeaders();

        // initialize collapsed state once based on the content's size
        const newColumnSizes = distributeEmptyWidth(sentinelRef, totalSize, flatHeaders);

        if (newColumnSizes != null) {
            setColumnSizing(newColumnSizes);
        }

        // additionally sync overflow state with changing dimensions of content
        const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            if (!Array.isArray(entries) || !entries.length) {
                return;
            }

            const totalSize = table.getTotalSize();
            const flatHeaders = table.getFlatHeaders();

            const newColumnSizes = distributeEmptyWidth(sentinelRef, totalSize, flatHeaders);

            if (newColumnSizes != null) {
                setColumnSizing(newColumnSizes);
            }
        });

        resizeObserver.observe(sentinelRef.current);

        // cleanup old observer before running effect with new dependencies (and creating a new observer)
        return () => resizeObserver.disconnect();
    }, []);

    return {
        tableRef,
        columnSizing,
        onColumnSizingChange,
        sentinelRef,
    };
}
