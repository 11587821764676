import { styled, TableSortLabel, tableSortLabelClasses, Typography } from "@mui/material";

import { flexRender, HeaderContext } from "@tanstack/react-table";
import Tooltip from "../../Tooltip";
import InfoIcon from "../../icons/InfoIcon";

const TableHeaderInfoIcon = styled(InfoIcon)(({ theme }) => ({
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5),
}));

const TableHeaderSortLabel = styled(TableSortLabel)(() => ({
    width: "100%",
    alignItems: "flex-start",

    [`& .${tableSortLabelClasses.icon}`]: {
        opacity: 0,
    },
    [`&:hover .${tableSortLabelClasses.icon}`]: {
        opacity: 1,
    },
    [`&.${tableSortLabelClasses.active} .${tableSortLabelClasses.icon}`]: {
        opacity: 1,
    },
}));

const TableHeaderCell = <D extends object = Record<string, unknown>>({ column, header }: HeaderContext<D, unknown>) => {
    const { columnDef } = column;

    const content = String(columnDef.meta?.label ?? column.columnDef.id);
    const headerTooltip = columnDef.meta?.tooltipHeader;

    const contentWithTooltip = (
        <Tooltip title={headerTooltip ?? content} placement="right" onlyOverflowing={headerTooltip == null}>
            <Typography component="div" variant="subtitle2" noWrap>
                {flexRender(content, header.getContext())}
                {headerTooltip != null && <TableHeaderInfoIcon />}
            </Typography>
        </Tooltip>
    );

    if (!column.getCanSort()) {
        return contentWithTooltip;
    }

    // only pass click-handler, other props (styling, tooltips) are provided by MUI
    const onClick = column.getToggleSortingHandler();
    const sortDir = column.getIsSorted();
    return (
        <TableHeaderSortLabel active={sortDir !== false} direction={sortDir === false ? "asc" : sortDir} onClick={onClick}>
            {flexRender(contentWithTooltip, header.getContext())}
        </TableHeaderSortLabel>
    );
};

export default TableHeaderCell;
