import { Typography, TypographyProps } from "@mui/material";
import { SHORT_TEXT_MAX_CHARS } from "api-shared";
import Tooltip from "../../Tooltip";

interface TableOverflowWrapper extends Pick<TypographyProps, "align" | "className"> {
    title?: string | null;
    children?: any;
}

const TableOverflowWrapper = ({ title, align, className, children }: TableOverflowWrapper) => {
    const fallbackTitle =
        typeof children === "string" && children.length > SHORT_TEXT_MAX_CHARS
            ? `${children.substring(0, SHORT_TEXT_MAX_CHARS - 1).trim()}…`
            : children;
    return (
        <Tooltip title={title ?? fallbackTitle} onlyOverflowing={title == null}>
            <Typography variant="body2" align={align} noWrap className={className}>
                {children}
            </Typography>
        </Tooltip>
    );
};

export default TableOverflowWrapper;
