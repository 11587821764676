import { styled } from "@mui/material";

interface ITableResizerProps {
    isActive?: boolean;
}

const TableResizer = styled("div")<ITableResizerProps>(({ isActive, theme }) => ({
    cursor: "col-resize",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: theme.spacing(0.75),
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
        borderLeft: `1px solid ${theme.palette.divider}`,
    },
    ...(isActive && {
        backgroundColor: theme.palette.action.selected,
        borderLeft: `1px solid ${theme.palette.divider}`,
    }),
}));

export default TableResizer;
