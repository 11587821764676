import { Skeleton, TableCell, TableRow } from "@mui/material";

type TableRowSkeletonsProps = {
    colSpan: number;
    count?: number;
};

function TableRowSkeletons({ colSpan, count = 10 }: TableRowSkeletonsProps) {
    return [...Array(count).keys()].map((i) => {
        return (
            <TableRow key={`skeleton_${i}`}>
                <TableCell colSpan={colSpan}>
                    <Skeleton />
                </TableCell>
            </TableRow>
        );
    });
}

export default TableRowSkeletons;
